var exports = {};

exports = str => {
  let hash = 5381,
      i = str.length;

  while (i) hash = hash * 33 ^ str.charCodeAt(--i);

  return hash >>> 0;
};

export default exports;